import React from 'react'
import { Link, graphql } from 'gatsby'
import './ProjectList.scss'
import RenderContent from '../../RenderContent'
import Pagination from '../Pagination'
import { FaChevronRight } from '../../../images/icons/FaChevronRight'
import PostListItem from '../PostListItem'
import { ProjectListNavigation } from '../ProjectListNavigation'

export default class IndexPage extends React.Component {
  render() {
    const {
      posts,
      title,
      pageContext,
      categoryFilter,
      categories,
      pathPrefix,
      siteMetadata,
      className = null,
    } = this.props
    return (
      <>
        <ProjectListNavigation categories={categories} />
        <section className={`project-list${className ? ` ${className}` : ''}`}>
          <div className="outer">
            <div className="wrap">
              <div className="inner">
                <div className="list">
                  {posts.map(
                    (slide, inedx) =>
                      slide.node.slug !== 'gatsby' && (
                        <PostListItem
                          key={inedx}
                          data={slide.node}
                          siteMetadata={siteMetadata}
                          pathPrefix={pathPrefix}
                        />
                      )
                  )}
                </div>
                <Pagination
                  pageContext={pageContext}
                  pathPrefix={`${pathPrefix}`}
                />
              </div>
            </div>
          </div>
        </section>
      </>
    )
  }
}

export const pageQuery = graphql`
  fragment ProjectListFields on wordpress__wp_projects {
    id
    project_category
    title
    excerpt
    wordpress_id
    date(formatString: "MMMM DD, YYYY")
    path
    slug
    featured_media {
      source_url
      localFile {
        childImageSharp {
          fluid(maxWidth: 1300) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  }
`
